export const createMapskyConfig = () => [
  {
    position: 'mapsky',
    slot: {
      name: 'pdp.mapsky',
      sizes: [
        { w: 160, h: 600 },
        { w: 120, h: 600 },
      ],
    },
  },
];
