const createConfig = (layout, position) =>
  layout === 'XL'
    ? [
        {
          position,
          slot: {
            name: `${position}XL`,
            sizes: [
              { w: 300, h: 600 },
              { w: 160, h: 600 },
            ],
          },
        },
      ]
    : [
        {
          position,
          slot: {
            name: position,
            sizes: [{ w: 160, h: 600 }],
          },
        },
      ];

export const createPhotoskyConfig = (layout) =>
  createConfig(layout, 'photosky');

export const createPhotosky2Config = (layout) =>
  createConfig(layout, 'photosky2');
