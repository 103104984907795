const createDetailPagePrebidConfig = (isSmallScreen) =>
  isSmallScreen
    ? [
        {
          position: 'medrec1',
          slot: {
            name: 'm.pdp.medrec1',
            sizes: [{ w: 300, h: 250 }],
          },
        },
      ]
    : [
        {
          position: 'header',
          slot: { name: 'pdp.header', sizes: [{ w: 728, h: 90 }] },
        },
        {
          position: 'medrec1',
          slot: {
            name: 'pdp.medrec1',
            sizes: [{ w: 300, h: 250 }],
          },
        },
        {
          position: 'medrec2',
          slot: {
            name: 'pdp.medrec2',
            sizes: [{ w: 300, h: 250 }],
          },
        },
        {
          position: 'medrec3',
          slot: {
            name: 'pdp.medrec3',
            sizes: [
              { w: 300, h: 600 },
              { w: 160, h: 600 },
              { w: 300, h: 250 },
            ],
          },
        },
      ];

export default createDetailPagePrebidConfig;
