const createDesktopStripConfig = () => {
  const stripConfig = [];
  for (let i = 1; i <= 5; i++) {
    stripConfig.push({
      position: `strip${i}`,
      slot: {
        name: `strip${i}`,
        sizes: [
          { w: 640, h: 200 },
          { w: 640, h: 380 },
        ],
      },
    });
  }
  return stripConfig;
};

const createMobileStripConfig = () => {
  const stripConfig = [];
  for (let i = 1; i <= 5; i++) {
    stripConfig.push({
      position: `strip${i}`,
      slot: {
        name: `m.srp.strip${i}`,
        sizes: [
          { w: 300, h: 250 },
          { w: 320, h: 50 },
        ],
      },
    });
  }
  return stripConfig;
};

const createResultPagePrebidConfig = (isSmallScreen) => {
  return isSmallScreen
    ? createMobileStripConfig()
    : [
        {
          position: 'header',
          slot: { name: 'header', sizes: [{ w: 728, h: 90 }] },
        },
        {
          position: 'devfeaturetop',
          slot: {
            name: 'devfeaturetop',
            sizes: [
              { w: 300, h: 600 },
              { w: 160, h: 600 },
              { w: 300, h: 250 },
              { w: 120, h: 600 },
            ],
          },
        },
        {
          position: 'skyscraper',
          slot: {
            name: 'skyscraper',
            sizes: [
              { w: 300, h: 600 },
              { w: 160, h: 600 },
              { w: 300, h: 250 },
              { w: 120, h: 600 },
            ],
          },
        },
        {
          position: 'medrec2',
          slot: {
            name: 'medrec2',
            sizes: [
              { w: 300, h: 600 },
              { w: 160, h: 600 },
              { w: 300, h: 250 },
              { w: 120, h: 600 },
            ],
          },
        },
        {
          position: 'medrec3',
          slot: {
            name: 'medrec3',
            sizes: [
              { w: 300, h: 600 },
              { w: 160, h: 600 },
              { w: 300, h: 250 },
              { w: 120, h: 600 },
            ],
          },
        },
        ...createDesktopStripConfig(),
      ];
};

export default createResultPagePrebidConfig;
